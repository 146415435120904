import { useEffect, useState } from "react";
import "./App.css";
//import { Wallet, Chain, Network,MetadataField} from "mintbase";
import * as nearAPI from "near-api-js";
import styled from "styled-components";
import ChippedArtLogo from "./ChippedArtLogo";
import LoadingOverlay from 'react-loading-overlay';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

var nettouse = 'testnet';
var baseurl = 'demo.chippedart';
var contractname = "chippedart-dev2.testnet";
var media ='';
var reference = '';
var sourceurl = '';
var cloneorcopy = 'copy';


const Container = styled.div`
  text-align: center;
  max-width: 750px;
  margin: auto;
`;

const Header = styled.div`
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
`;

const Link = styled.a`
  color: white;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Button = styled.button`
  height: 50px;
  margin: 10px;
`;

const DetailsContainer = styled.div`
  max-width: 100%;
`;

const Details = styled.p`
  font-size: 18px;
`;

const DisplayForm = styled.table`
	text-align: left;
	border: 1px solid white;
	padding: 5px;
    border-spacing: 10px;
    border-collapse: separate;
    -moz-border-radius:10px;
    -webkit-border-radius:10px;
    border-radius:10px
	width:200px;

	
`;








function App() {
	
	var tagid=''
	var dbuid='';
	var tokenminted=false;
	var accountsuffix=''
	
	var callingurl = window.location.href;
	if(callingurl.includes(".demo.")){
		nettouse="testnet"
		baseurl = "demo.chippedart";
		contractname = "chippedart-dev2.testnet";
		accountsuffix = 'testnet';
	}
	else{
		nettouse="mainnet"
		baseurl = "chippedart";
		contractname = "chippedart-nft.near"
		accountsuffix = 'near';
	}
	

	
	const { connect, keyStores, WalletConnection, utils } = nearAPI;

	
	const config = {
	  networkId: "testnet",
	  keyStore: new keyStores.BrowserLocalStorageKeyStore(),
	  nodeUrl: "https://rpc."+nettouse+".near.org",
	  walletUrl: "https://wallet."+nettouse+".near.org",
	  helperUrl: "https://helper."+nettouse+".near.org",
	  explorerUrl: "https://explorer."+nettouse+".near.org",
	};

	var wallet;
	var waitforit = false;
	var clone = false;


	
	console.log(window.location.search)
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
		
			if(pair[0]==='tagid'){

				tagid = decodeURIComponent(pair[1]);
			}
			if(pair[0]==='dbuid'){

				dbuid = decodeURIComponent(pair[1]);
			}
			if(pair[0]==='transactionHashes'){

				tokenminted=true;
				console.log('token minted')
			}
			if(pair[0]==='waitforit'){
				waitforit = true;

			}
			if(pair[0]==='clone'){
				clone=true;
			}
        
    }
	
	console.log(tagid);

	
   const handleChange = (selectorFiles: FileList | null) =>
    {
        console.log(selectorFiles);
		if(!selectorFiles)return;

		var encrypt = "Y";
		if(!encryptimage)encrypt="N";
		var urlpost = new URLSearchParams();
		urlpost.append("cmd","uploadfile")
		urlpost.append("dbuid",dbuid);
		urlpost.append("encrypt",encrypt);
		urlpost.append("minter",minter);
		urlpost.append("uploadtype","image");
		console.log(selectorFiles[0])
		
		if(selectorFiles[0]!==undefined){
			
			setPleaseWaitText('Please Wait. Uploading your image');
			setPleaseWait(true);
			
			var reader = new FileReader();
				
			reader.readAsDataURL(selectorFiles[0]);
			console.log(reader)
			reader.onload = function () {
				if(reader.result!==null && reader.result!==undefined){
					var infile = ''
					infile = reader.result+'';
					console.log(infile)
					urlpost.append("infile",infile)
					console.log('about to call api')

					var url = "https://api."+baseurl+".com/api.php"
					fetch(url,{
						method:'POST',
						headers: {'Content-Type': 'application/x-www-form-urlencoded'},
						body: urlpost
					})
					.then(res => res.json())
					.then((resp) => {
						console.log(resp)	
						if(resp.error===""){
							setMainFileURI(resp.data.uri);
							setMainFileHash(resp.data.hash);
							setIsReadyToMint(true);
							setPleaseWait(false);
						}else{
							toast(resp.error)
							setPleaseWait(false);
						}
					})
						
				}		 
				 
			};
		}

		
    }
	
	
   const handleDocument = (documentFile: FileList | null) =>
    {
        console.log(documentFile);
		if(!documentFile)return;

		var encrypt = "Y";
		if(!encryptimage)encrypt="N";
		var urlpost = new URLSearchParams();
		urlpost.append("cmd","uploadfile")
		urlpost.append("dbuid",dbuid);
		urlpost.append("encrypt",encrypt);
		urlpost.append("uploadtype","document");
		console.log(documentFile[0])
		
		if(documentFile[0]!==undefined){
			
			setPleaseWaitText('Please Wait. Uploading your document');
			setPleaseWait(true);
		
			var reader = new FileReader();
				
			reader.readAsDataURL(documentFile[0]);
			console.log(reader)
			reader.onload = function () {
				if(reader.result!==null && reader.result!==undefined){
					var infile = ''
					infile = reader.result+'';
					console.log(infile)
					urlpost.append("infile",infile)
					console.log('about to call api')

					var url = "https://api."+baseurl+".com/api.php"
					fetch(url,{
						method:'POST',
						headers: {'Content-Type': 'application/x-www-form-urlencoded'},
						body: urlpost
					})
					.then(res => res.json())
					.then((resp) => {
						if(resp.error===""){
							console.log(resp)	
							setDocumentFileURI(resp.data.uri);
							setDocumentFileHash(resp.data.hash);
							setIsReadyToMint(true);
							setPleaseWait(false);
						}else{
							toast(resp.error)
							setPleaseWait(false);
						}
					})
						
				}		 
				 
			};
		}

		
    }



   const handleDescription = (thingDescription: string | null) =>
    {
		if(!thingDescription)return;
        console.log(thingDescription);
		setDescription(thingDescription);
		
    }

   const handleTitle = (thingTitle: string | null) =>
    {
		if(!thingTitle)return;
        console.log(thingTitle);
		setTitle(thingTitle);
		
    }
	
	const handleDocName = (documentName: string | null) =>
    {
		if(!documentName)return;
		setDocName(documentName);
		
    }


	const handleDocDescription = (documentDescription: string | null) =>
    {
		if(!documentDescription)return;
		setDocDescription(documentDescription);
		
    }
	
	const [showfileupload, setShowFileUpload ] = useState(false);
	
	const handleEncrypt = (encryptflag: String | null ) =>
	{
		console.log(encryptflag)
		if(encryptflag!=null){
			setShowFileUpload(true);
			if(encryptflag==="Y")
				setEncryptImage(true)
			if(encryptflag==="N" || encryptflag==='')
				setEncryptImage(false)
			if(encryptflag==='')setShowFileUpload(false);
		}
	}
	
	const handleClone = (cloneid: string | null ) =>
	{
		console.log(cloneid)
		if(cloneid!==null)
		setTag(cloneid);

	}
	
	const handleBCCode = (bcCode: string | null) =>
    {
		if(!bcCode)return;
		bcCode = bcCode.toLowerCase();
        console.log(bcCode);
		setBCCode(bcCode);
		
    }

	const handleBCID = (bcId: string | null) =>
    {
		if(!bcId)return;
		bcId = bcId.toLowerCase();
        console.log(bcId);
		setBCID(bcId);
		
		var json = '{"jsonrpc": "2.0","id": "dontcare","method": "query","params": ["account/'+bcId+'.'+accountsuffix+'",""]}'
		console.log(json)

		fetch(config.nodeUrl,{
			method:'POST',
			headers: {'Content-Type': 'application/json'},
			body: json
		})
		.then(res => res.json())
		.then(
			(result) => {
				console.log(result)	
				if(result.error!==undefined){
					// Account does not exist. Show green
					setBCIDColor('green')
				}else{
					// Account exists show red
					setBCIDColor('red')
					
				}
				

		})
		
		
    }

	
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isReadyToMint, setIsReadyToMint] = useState(false);
  
  const [hash, setMainFileHash] = useState('');
  const [uri, setMainFileURI] = useState('');
  
  const [documenturi, setDocumentFileURI] = useState('');
  const [documenthash, setDocumentFileHash] = useState('');

  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');
  const [inpromise, setPleaseWait] = useState(false);
  const [pleasewaittext, setPleaseWaitText] = useState('');
  const [encryptimage, setEncryptImage] = useState(false);  
  const [minter, setMinter] = useState('');  
  
  const [documentdescription, setDocDescription] = useState('');
  const [documentname, setDocName] = useState('');
  
  const [needtoregister, setNeedToRegister] = useState(false);
  
  const [bccode, setBCCode] = useState('');
  const [bcid, setBCID] = useState('');
  const [bcidcolor, setBCIDColor] = useState('green');
  
  const [tag, setTag] = useState('')
  const [tags, setTags] = useState([{label:"a",value:"b"}])
  
  const [details, setDetails] =
    useState<{
      accountId: string;
      balance: string;
      allowance: string;
      contractName: string;
    }>();


  const initWallet = async () => {

	const near = await connect(config);
	wallet = new WalletConnection(near,"ChippedArt");
	console.log(wallet.getAccountId());
	
	if(wallet.isSignedIn()){
		console.log('signed in');
		const account = await wallet.account();
		var details = {accountId:"",balance:"",allowance:"",contractName:""};
		if(details!==undefined){
			details.accountId = wallet.getAccountId();
			details.balance = utils.format.formatNearAmount((await account.getAccountBalance()).available,2);
			details.allowance = "";
			details.contractName = "";
			setDetails(details);
			console.log("set details");
			setMinter(wallet.getAccountId());

		}
		setIsLoggedIn(true);
		
		if(clone===true){
			setPleaseWaitText('Please Wait. Getting previous tags)');
			setPleaseWait(true);
			var urlpost = new URLSearchParams();
			urlpost.append("cmd","getnfts")
			urlpost.append("account",details.accountId)
			console.log('about to call api '+details.accountId)

			var url = "https://api."+baseurl+".com/api.php"
			fetch(url,{
				method:'POST',
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
				body: urlpost
			})
			.then(res => res.json())
			.then(
				(result) => {
					console.log(result)	
					var taglist: Array<any> = []
					result.message.forEach( (e: Array<any>) => {
						console.log(e)
						// @ts-ignore
						taglist.push({label: e.title+' ('+e.mintdate+')', value:e.id })
					});
					setTags(taglist);
					setPleaseWait(false)
					
					

				})
		}
		
		
		

	}else{
	
		console.log('not signed in');
		setIsLoggedIn(false);
	}
	
	if(waitforit){
		setPleaseWaitText('Please Wait. Setting up Account');
		setPleaseWait(true);
	}


  };

  const handleConnect = async (shouldRequest: boolean) => {

	// connect to NEAR
	const near = await connect(config);
	// create wallet connection
	wallet = new WalletConnection(near,"ChippedArt");
	
	if(wallet.isSignedIn()){
		console.log('signed in');
	}else{
		console.log(wallet);
		wallet.requestSignIn("","ChippedArt");
	}
  };
  


  const handleDisconnect = async () => {
	  
	// connect to NEAR
	const near = await connect(config);

	// create wallet connection
	const wallet = new WalletConnection(near,"ChippedArt");

	await wallet.signOut();
    window.location.reload();
  };
  
  const duplicatefiles = async () => {
	// Get new media file
	
	var retresult

	var urlpost = new URLSearchParams();
	urlpost.append("cmd","duplicatefile")
	urlpost.append("sourceurl",sourceurl)
	urlpost.append("sourcetag",tag)
	urlpost.append("destinationtag",dbuid)

	var url = "https://api."+baseurl+".com/api.php"
	await fetch(url,{
		method:'POST',
		headers: {'Content-Type': 'application/x-www-form-urlencoded'},
		body: urlpost
	})
	.then(res => res.json())
	.then(
		(result) => {
			console.log(result)	
			retresult = result
		}
	)
	return retresult
	  
  }

  const handleCloneChoice = async (clonechoice: string  ) => {
	console.log('clone choice' + clonechoice)

	cloneorcopy = clonechoice;
  }

  
  const handleCloneClick = async () => {
	console.log('do clone' + tag)
	
	var clone_title='';
	var clone_description='';
	var clone_mediauri ='';
	var clone_mediahash='';
	var clone_documenturi='';
	var clone_documenthash='';
	var clone_documentname = '';
	var clone_documentdescription='';
	// Get source token details
	if(tag!==''){
		var json = '{"jsonrpc": "2.0","id": "dontcare","method": "query","params": {"request_type": "call_function","finality": "final","account_id": "'+contractname+'","method_name": "nft_token","args_base64": "'+btoa('{"token_id":"'+tag+'"}')+'"}}';
		console.log(json);
		await fetch(config.nodeUrl,{
			method:'POST',
			headers: {'Content-Type': 'application/json'},
			body: json
		})
		.then(async res => res.json())
		.then(
			async (result) => {
				console.log('got details of token to be cloned')
				console.log(result)	
				var mess = "";
				result.result.result.forEach( (e: number) => {
					mess+=String.fromCharCode(e)
				})
				var tokendets = JSON.parse(mess)
				console.log(tokendets)
				media = tokendets.metadata.media
				reference = tokendets.metadata.reference
				// Get the document file
				if(reference!==""){
					console.log('This token has a reference')
					var url = reference
					await fetch(url,{
						method:'GET'
					})
					.then(async res => res.json())
					.then(
						async (result) => {
							console.log('got reference details')
							console.log(result)
							sourceurl = result.document
							if(sourceurl!==""){
								console.log('has a valid document attached. copy it')
								var res = await duplicatefiles()
								console.log(res);
								if(res!==undefined){
									// @ts-ignore
									clone_documenturi = res.data.uri
									// @ts-ignore
									clone_documenthash = res.data.hash
								}
								clone_documentname = result.documentname
								clone_documentdescription = result.documentdescription
							}

						}
					)
				}
				console.log('duplicating media')
				sourceurl = media
				var res = await duplicatefiles()
				console.log(res);
				if(res!==undefined){
					// @ts-ignore
					clone_mediauri = res.data.uri
					// @ts-ignore
					clone_mediahash = res.data.hash
				}
				clone_title = tokendets.metadata.title;
				clone_description = tokendets.metadata.description;
				
				setPleaseWaitText('Please Wait. Registering your signing (You will be redirected to your wallet)');
				setPleaseWait(true);
				
				var urlpost = new URLSearchParams();
				urlpost.append("cmd","registermint")
				urlpost.append("dbuid",dbuid)
				urlpost.append("documenturi",clone_documenturi)
				urlpost.append("documenthash",clone_documenthash)
				urlpost.append("documentname",clone_documentname)
				urlpost.append("documentdescription",clone_documentdescription)
				console.log(clone_documenturi);
				
				
				console.log('registering cloned mint in database')
				
				const near = await connect(config);
				var wallet = new WalletConnection(near,"ChippedArt");
						
				// @ts-ignore
				const contract = new nearAPI.Contract(
					wallet.account(), // the account object that is connecting
					contractname,
					{
						// name of contract you're connecting to
						viewMethods: ["nft_token"], // view methods do not change state but usually return a value
						changeMethods: ["nft_mint"], // change methods modify state
					}
				);
						
				var thetime = Date.now()+"";
				
				
				url = "https://api."+baseurl+".com/api.php"
				fetch(url,{
					method:'POST',
						headers: {'Content-Type': 'application/x-www-form-urlencoded'},
						body: urlpost
					})
					.then(res => res.json())
					.then((result) => {
						console.log(result)	
						var newdocumenturi = result.message.metadataid;
						var newdocumenthash = result.message.metadatahash;
						var parent_seed = result.message.parent_seed;
						var this_seed_hash = result.message.this_seed_hash;
						var extra = '';
						if(cloneorcopy==='copy')
							extra = '{"parent_seed":"'+parent_seed+'","this_seed_hash":"'+this_seed_hash+'"}';
						else
							extra = '{"parent_seed":"'+parent_seed+'","this_seed_hash":"'+this_seed_hash+'","linked_to":"'+tag+'"}';
						
						// @ts-ignore
						contract.nft_mint(
							{
								token_id: tagid,
								owner_id: contractname,
								token_owner_id: wallet.getAccountId(),
								receiver_id: wallet.getAccountId(),
								token_metadata: {title: clone_title, description: clone_description,media: clone_mediauri, media_hash: clone_mediahash, copies: 1, issued_at: thetime, expires_at: null,starts_at: thetime,updated_at: null,extra: extra,reference: newdocumenturi,reference_hash: newdocumenthash, minter: wallet.getAccountId() },
						  },'300000000000000','800000000000000000'
						);
						
					})
				
				
				

				
			}
		)
	}
	
		
	  
  }
  
	const checkMint = () => {
			
		var urlpost = new URLSearchParams();
		urlpost.append("cmd","checkmint")
		urlpost.append("dbuid",dbuid)
		console.log('about to call api')

		var url = "https://api."+baseurl+".com/api.php"
		fetch(url,{
			method:'POST',
			headers: {'Content-Type': 'application/x-www-form-urlencoded'},
			body: urlpost
		})
		.then(res => res.json())
		.then(
			(result) => {
				console.log(result)	


			})
	}
	
	const handleRegister = () => {
		setNeedToRegister(true);
	}
	
	const doBCRegistration = async () => {

		console.log("BC Registration")
		var myurl = window.location.origin+window.location.pathname;
		const tab = window.open(myurl+"?waitforit");
		
		setPleaseWaitText('Please Wait. Creating your wallet');
		setPleaseWait(true);
		var urlpost = new URLSearchParams();
		urlpost.append("cmd","checkbcregistration")
		urlpost.append("dbuid",dbuid);
		urlpost.append("bccode",bccode);
		urlpost.append("bcid",bcid);
		
		var url = "https://api."+baseurl+".com/api.php"
		fetch(url,{
			method:'POST',
			headers: {'Content-Type': 'application/x-www-form-urlencoded'},
			body: urlpost
		})
		.then(res => res.json())
		.then((resp) => {
			if(resp.error===""){
				console.log(resp)
				console.log(resp.message)
				if(tab!==null){
					if(resp.message.startsWith("https://")){
						tab.location = resp.message;
						setNeedToRegister(false)
					}else{
						tab.close();
					}
					
				}
				
				setPleaseWait(false);
			}else{
				if(tab!==null)tab.close();
				toast(resp.error);
				setPleaseWait(false);
			}
		})
		
	}
  
  
	const handleMint = async () => {


		setPleaseWaitText('Please Wait. Registering your signing (You will be redirected to your wallet)');
		setPleaseWait(true);
		
		var urlpost = new URLSearchParams();
		urlpost.append("cmd","registermint")
		urlpost.append("dbuid",dbuid)
		urlpost.append("documenturi",documenturi)
		urlpost.append("documenthash",documenthash)
		urlpost.append("documentname",documentname)
		urlpost.append("documentdescription",documentdescription)
		
		console.log('registering mint in database')
		
		const near = await connect(config);
		var wallet = new WalletConnection(near,"ChippedArt");
				
		// @ts-ignore
		const contract = new nearAPI.Contract(
			wallet.account(), // the account object that is connecting
			contractname,
			{
				// name of contract you're connecting to
				viewMethods: ["nft_token"], // view methods do not change state but usually return a value
				changeMethods: ["nft_mint"], // change methods modify state
			}
		);
				
		var thetime = Date.now()+"";
		
		
		var url = "https://api."+baseurl+".com/api.php"
		fetch(url,{
			method:'POST',
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
				body: urlpost
			})
			.then(res => res.json())
			.then((result) => {
				console.log(result)	
				var newdocumenturi = result.message.metadataid;
				var newdocumenthash = result.message.metadatahash;
				var parent_seed = result.message.parent_seed;
				var this_seed_hash = result.message.this_seed_hash;
				var extra = '{"parent_seed":"'+parent_seed+'","this_seed_hash":"'+this_seed_hash+'"}';
				
				// @ts-ignore
				contract.nft_mint(
					{
						token_id: tagid,
						owner_id: contractname,
						token_owner_id: wallet.getAccountId(),
						receiver_id: wallet.getAccountId(),
						token_metadata: {title: title, description: description,media: uri, media_hash: hash, copies: 1, issued_at: thetime, expires_at: null,starts_at: thetime,updated_at: null,extra: extra,reference: newdocumenturi,reference_hash: newdocumenthash, minter: wallet.getAccountId() },
				  },'300000000000000','800000000000000000'
				);
				
			})
		 
	};
	




  useEffect(() => {
    initWallet();
	checkMint();
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  return (
  <LoadingOverlay
  active={inpromise}
  spinner
  text={pleasewaittext}
  >

	<Container>
	


		<Header>
			<ToastContainer />
			<LogoContainer>
				<ChippedArtLogo width={200}/>
				<br/>
				<span>
				<Link href="https://www.chippedart.com">
					ChippedArt
				</Link>
				</span>
			</LogoContainer>
			
			{!waitforit && (
			
				<>
				
				
				
			
				{!tokenminted  && (
					
					<>

					{isLoggedIn && details && (
						<DetailsContainer>
							<Details>Account: {details.accountId} ({details.balance}N)</Details>
				
							{isLoggedIn && details && !clone && (
			

								<DisplayForm>
									<tr><td >Title</td><td><input onChange={ (e) => handleTitle(e.target.value) }  style={{width: "167px"}}/></td></tr>
									<tr><td>Description</td><td><textarea onChange={ (e) => handleDescription(e.target.value) } style={{width: "167px"}} /></td></tr>
									<tr><td>Encrypt Files?</td><td><select onChange={ (e) => handleEncrypt(e.target.value) }><option value=''>[Select]</option><option value='N'>No</option><option value='Y'>Yes</option></select></td></tr>
									<tr><td colSpan={2}>
										{showfileupload && (
										<table>
											<tr><td>Picture</td></tr>
											<tr><td colSpan={2}><input type="file" onChange={ (e) => handleChange(e.target.files) } /></td></tr>
											<tr><td>Documention</td></tr>
											<tr><td colSpan={2}><input type="file" onChange={ (e) => handleDocument(e.target.files) } /></td></tr>									
										</table>
										)}
									</td></tr>
									{showfileupload && (
									<>
									<tr><td>Doc Name</td><td><input onChange={ (e) => handleDocName(e.target.value) }  style={{width: "167px"}} /></td></tr>
									<tr><td>Doc Description</td><td><textarea onChange={ (e) => handleDocDescription(e.target.value) }  style={{width: "167px"}} /></td></tr>
									</>
									)}


								</DisplayForm>
							)}
							
							{clone && (
								<DisplayForm>
									<tr><td>Copy or Link?</td><td><select onChange={ (e) => handleCloneChoice(e.target.value) }><option value='copy'>Copy</option><option value='link'>Link</option></select></td></tr>
									<tr><td>Tag to Copy/Link</td><td><select onChange={ (e) => handleClone(e.target.value) }><option value=''>[Tag to Copy/Link]</option>{tags.map((tag) => <option value={tag.value}>{tag.label}</option>)}</select></td></tr>
									<tr><td colSpan={2}><i>Copying</i> a tag creates a totally independent tag programmed with the same title, media and contents as the original<br/><br/><i>Linking</i> a tag creates a tag that, when scanned, displays the information, and all updates from the tag it is linked to</td></tr>
								</DisplayForm>
							
							)}

				
						</DetailsContainer>
					)}
					{needtoregister && (
						<>
						<DetailsContainer>
								<br/>
								<DisplayForm>
									<tr><td>Activation Code</td><td><input onChange={ (e) => handleBCCode(e.target.value) }  style={{width: "167px"}}/></td></tr>
									<tr><td>Choose Wallet ID</td><td><input onChange={ (e) => handleBCID(e.target.value) }  style={{width: "90px", color: bcidcolor}}/> .{accountsuffix}</td></tr>
								</DisplayForm>
						</DetailsContainer>
						<ButtonsContainer>
							<Button onClick={()=>doBCRegistration()}>Create Wallet</Button><Button onClick={() => setNeedToRegister(false)}>Back</Button>
						</ButtonsContainer>
						</>
					)}
					
					{!needtoregister && (
				
					<ButtonsContainer>
						{!isLoggedIn && (
							<>
							<Button onClick={() => handleConnect(true)}>Connect to Wallet</Button>
							<Button onClick={() => handleRegister()}>Setup Wallet</Button>
							</>
						)}
						{isLoggedIn && (
							<>
							<Button onClick={() => handleDisconnect()}>Disconnect</Button>
							</>
						)}
						{isLoggedIn && clone && (
							<>
							<Button onClick={() => handleCloneClick()}>Copy or Link</Button>
							</>
						)}						
						{isReadyToMint && (
							<Button onClick={() => handleMint()}>Register your signing</Button>
						)}
					</ButtonsContainer>
					
					)}
					
					</>
				)}
				
				{tokenminted && (
					<span>Signed! Please close this window and re-scan the tag to verify</span>
				)}
				</>
			)}
			{waitforit && (
				<>
				<span>Setting up account on NEAR</span><br/>
				<span>Please Recover the Wallet and close this window when done</span>
				</>
			)}
		</Header>
		
				  
    </Container>
	</LoadingOverlay>
  );
}

export default App;
